html {
  background: url(/image/pub-background-camra.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

body {
  font-family: Arial, serif;
  background-color: rgba(0, 0, 0, 0);
}

#camra-logo {
  padding-top: 50px;
}

.container--main {
  padding-top: 20px;
}

.panel--transparent {
  background-color: rgba(255, 255, 255, 0.7);
}

.footer {
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 40px;
  background-color: rgba(11, 11, 11, 0.3);
  padding-top: 10px;
  padding-bottom: 10px;
}

.message {
  background-color: #FFFFFF;
  padding: 15px 28px;
  margin-bottom: 20px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  font: 20px Georgia, "Times New Roman", Times, serif;
}